// Site layout: main content full page for mobile/tablet
.lines {
    z-index: 3;
    .line {
        width: 1px;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.5);
    }
    .line:first-child {
        left: 20%;
    }
    .line:nth-child(2) {
        left: 40%;
    }
    .line:nth-child(3) {
        left: 60%;
    }
    .line:nth-child(4) {
        left: 80%;
    }
    .line:last-child {
        left: 100%;
    }
}

.section .container {
    z-index: 4;
}

.section-full-height {
    @include desktop {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.bbw-logo {
    width: 120px;
}

.downloads {
    margin-top: 2rem;
    &-btn {
        line-height: 0;
        width: auto;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        @include tablet {
            width: auto;
            height: 50px;
        }
        &.google-svg {
            margin-left: 1rem;
        }
        img,
        svg {
            height: 100%;
        }
    }
}

.top-phones {
    margin: $space-double 0;
    &-ios,
    &-android {
        display: inline-block;
        vertical-align: middle;
        width: 45%;
        position: relative;
    }
    &-ios {
        z-index: 10;
        @include desktop {
            top: 6rem;
            opacity: 0;
        }
    }
    &-android {
        @include desktop {
            top: 4rem;
            opacity: 0;
        }
        margin-left: -4rem;
    }
}

.overview {
    text-align: center;
    &-one,
    &-two,
    &-three {
        display: inline-block;
        vertical-align: middle;
        width: 25%;
        margin: 3%;
        text-align: center;
        img {
            width: 100%;
        }
    }
    &-two {
        width: 30%;
    }
}

.banner-block {
    margin: $space 0;
    padding: $space-half;
    @include desktop {
        margin: $space-double 0;
        padding: $space;
    }
    &-img {
        width: 70%;
        max-width: 150px;
        margin-bottom: $space-half;
        @include desktop {
            max-width: 200px;
            margin-bottom: $space;
        }
    }
    small {
        z-index: 10;
        position: relative;
    }
    .title {
        position: relative;
        @include desktop {
            padding: $space-half;
            margin: $space-half 0;
        }
        span {
            z-index: 10;
            position: relative;
        }
        &:before {
            content: '';
            position: absolute;
            top: 10%;
            right: -1em;
            width: 95%;
            height: 60%;
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
}

//Swiper
.swiper-container {
    padding: 50px 0;
    max-width: 400px;
}

.swiper-slide {
    text-align: center;
    img {
        width: 80%;
        margin: auto;
    }
}

.slide-legend {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 40%;
        height: 2.3em;
        background-color: $yellow;
    }
    &-text {
        position: relative;
        z-index: 2;
    }
}

.swiper-pagination-bullet-active {
    background: $yellow;
}