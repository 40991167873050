// Common elements, css reset
html {
    box-sizing: border-box;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    &,
    &::before,
    &::after {
        box-sizing: inherit;
    }
}

::selection {
    background: $yellow;
}

img,
video {
    max-width: 100%;
}

h2.title {
    background-color: $yellow;
    width: fit-content;
    &.centered {
        margin-left: auto;
        margin-right: auto;
    }
}

a {
    color: inherit; // Avoid blue/purple default links color
    transition: color 0.3s;
}

a:hover,
a.active {
    color: $yellow;
    transition: color 0.3s;
}

img {
    display: inline-block; // To avoid margin bug under images
}

.grey-wh-gradient {
    background: $grey-wh-gradient;
}

.white-gr-gradient {
    background: $white-gr-gradient;
}

.yellow-banner {
    background-color: $yellow;
}

.link-y {
    position: relative;
    span {
        position: relative;
        z-index: 2;
    }
    .link-y-rect {
        position: absolute;
        width: 80%;
        height: 100%;
        top: -1em;
        right: -1em;
        background-color: $yellow;
        z-index: 1;
    }
    &:hover,
    &.active {
        color: inherit;
    }
}

.v-align {
    @include desktop {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .v-align-center {
            flex: none;
        }
        .v-align-top {
            align-self: flex-start;
        }
    }
}

.video {
    margin: 2em auto;
    width: 80%;
    max-width: 400px;
    display: block;
    box-shadow: $shadow;
}

.reverse-mobile-col-order {
    @include mobile {
        display: flex;
        flex-direction: column-reverse;
    }
}