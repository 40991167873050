.hero {
    background: $light-grey;
    &-arrow {
        width: 25px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em;
    }
    &-link {
        display: inline-block;
        vertical-align: middle;
    }
}

.hero-absolute {
    @include fullhd {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.hero-body {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    @include desktop {
        padding: 3rem 1.5rem;
    }
}