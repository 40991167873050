//Bulma - overwrite variables
$family-sans-serif: 'Ubuntu', sans-serif;
$title-weight: 700;
$content-heading-weight: 700;

// Colors
$white: #ffffff;
$light-grey: #f0f0f0;
$dark-grey: #3c3c3c;
$black: #000000;
$yellow: #ffe313;

//Gradient
$grey-wh-gradient: linear-gradient(to bottom, rgba(240, 240, 240, 1) 0%, rgba(255, 255, 255, 1) 100%); 
$white-gr-gradient: linear-gradient(to bottom, rgba(255, 255, 255, 1)  0%, rgba(240, 240, 240, 1) 100%); 

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
// Space
$space: 1.5rem;
$space-half: 0.75rem;
$space-double: 3rem;
$space-triple: 4.5rem;

