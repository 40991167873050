
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);

//vars
@import 'common/var';

//node
@import 'bulma/sass/utilities/_all';
@import 'bulma/sass/base/_all';
@import 'bulma/sass/elements/_all';
@import 'bulma/sass/components/_all';
@import 'bulma/sass/layout/_all';
@import 'bulma/sass/grid/_all';

@import 'swiper/dist/css/swiper';


// Common
@import 'common/common';
@import 'common/fonts';
@import 'common/layout';

// Layout
@import 'layout/form';
@import 'layout/text';

// Components
@import 'components/button';

// Blocks
@import 'blocks/header';
@import 'blocks/menu';
@import 'blocks/footer';
